var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-size":"100% 100%","height":"calc(100vh - 120px)","background-color":"#fff","background-repeat":"no-repeat","overflow-y":"scroll","padding-bottom":"120px"},style:({ backgroundImage: 'url(' + _vm.ranklist[_vm.rankCur].topbg + ')' })},[_c('div',{staticClass:"roomNav display_flex justify-content_flex-center align-items_center"},_vm._l((_vm.ranklist),function(item,index){return _c('div',{key:index,staticClass:"room-title",class:{ romCur: _vm.rankCur == index },on:{"click":function($event){return _vm.rankchange(index, item.list_type)}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.rankCur == index)?_c('i'):_vm._e()])}),0),_c('div',{staticClass:"ranktype"},_vm._l((_vm.ranklist[_vm.rankCur].type),function(item,index){return _c('div',{key:index,staticClass:"romitem",class:{ romName: _vm.ranklist[_vm.rankCur].cur == index },on:{"click":function($event){return _vm.typechange(index, item.sort_date)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),(_vm.nodata)?_c('div',{staticClass:"noData"},[_c('img',{attrs:{"src":'img/noData.png',"alt":""}})]):_vm._e(),(_vm.topThreeItems.length > 0)?_c('div',{staticClass:"room-three display_flex justify-content_flex-center"},[(_vm.topThreeItems.length > 0)?_c('div',{staticClass:"box-top1 display_flex flex-direction_column align-items_center",staticStyle:{"background-image":"url('img/top_one_bg.png')"},on:{"click":function($event){return _vm.checkUser(_vm.topThreeItems[0].user_id)}}},[_c('div',{staticClass:"box-head1"},[_c('img',{staticClass:"box-img",attrs:{"src":_vm.topThreeItems[0].head_portrait,"alt":""}})]),_c('div',{staticClass:"box-news"},[[_c('div',{staticClass:"box-name"},[_vm._v(_vm._s(_vm.topThreeItems[0].nickname))])],_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"box-level",style:({
              backgroundImage:
                'url(' +
                'img/level/level' +
                _vm.rankCur +
                '/' +
                _vm.topThreeItems[0].user_level +
                '.png' +
                ')',
            })})]),_c('div',{staticStyle:{"margin-top":"9px"}},[(_vm.rankCur == 0)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("财富值")]):_vm._e(),(_vm.rankCur == 1)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("魅力值")]):_vm._e(),(_vm.rankCur == 2)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("惊喜值")]):_vm._e(),(_vm.rankCur == 3)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("幸运值")]):_vm._e(),_c('span',{staticStyle:{"color":"#ffed38","font-weight":"700"}},[_vm._v(_vm._s(_vm.is_admin ? _vm.topThreeItems[0].total_charm_value : "****"))])])],2)]):_vm._e(),(_vm.topThreeItems.length > 1)?_c('div',{staticClass:"box-top2 display_flex flex-direction_column align-items_center",staticStyle:{"background-image":"url('img/top_two_bg.png')"},on:{"click":function($event){return _vm.checkUser(_vm.topThreeItems[1].user_id)}}},[_c('div',{staticClass:"box-head2"},[_c('img',{staticClass:"box-img",staticStyle:{"border":"2px solid #e5faff"},attrs:{"src":_vm.topThreeItems[1].head_portrait,"alt":""}})]),_c('div',{staticClass:"box-news"},[[_c('div',{staticClass:"box-name"},[_vm._v(_vm._s(_vm.topThreeItems[1].nickname))])],_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"box-level",style:({
              backgroundImage:
                'url(' +
                'img/level/level' +
                _vm.rankCur +
                '/' +
                _vm.topThreeItems[1].user_level +
                '.png' +
                ')',
            })})]),_c('div',{staticStyle:{"margin-top":"9px"}},[(_vm.rankCur == 0)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("财富值")]):_vm._e(),(_vm.rankCur == 1)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("魅力值")]):_vm._e(),(_vm.rankCur == 2)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("惊喜值")]):_vm._e(),(_vm.rankCur == 3)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("幸运值")]):_vm._e(),_c('span',{staticStyle:{"color":"#ffed38","font-weight":"700"}},[_vm._v(_vm._s(_vm.is_admin ? _vm.topThreeItems[1].total_charm_value : "****"))])])],2)]):_vm._e(),(_vm.topThreeItems.length > 2)?_c('div',{staticClass:"box-top3 display_flex flex-direction_column align-items_center",staticStyle:{"background-image":"url('img/top_three_bg.png')"},on:{"click":function($event){return _vm.checkUser(_vm.topThreeItems[2].user_id)}}},[_c('div',{staticClass:"box-head2"},[_c('img',{staticClass:"box-img",staticStyle:{"border":"2px solid #ffede0"},attrs:{"src":_vm.topThreeItems[2].head_portrait,"alt":""}})]),_c('div',{staticClass:"box-news"},[[_c('div',{staticClass:"box-name"},[_vm._v(_vm._s(_vm.topThreeItems[2].nickname))])],_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"box-level",style:({
              backgroundImage:
                'url(' +
                'img/level/level' +
                _vm.rankCur +
                '/' +
                _vm.topThreeItems[2].user_level +
                '.png' +
                ')',
            })})]),_c('div',{staticStyle:{"margin-top":"9px"}},[(_vm.rankCur == 0)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("财富值")]):_vm._e(),(_vm.rankCur == 1)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("魅力值")]):_vm._e(),(_vm.rankCur == 2)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("惊喜值")]):_vm._e(),(_vm.rankCur == 3)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("幸运值")]):_vm._e(),_c('span',{staticStyle:{"color":"#ffed38","font-weight":"700"}},[_vm._v(_vm._s(_vm.is_admin ? _vm.topThreeItems[2].total_charm_value : "****"))])])],2)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"list-scroll",staticStyle:{"margin-top":"180px"}},[(_vm.listItems.length > 0)?_vm._l((_vm.listItems),function(item,index){return _c('div',{key:index,staticClass:"item display_flex justify-content_flex-justify align-items_center"},[_c('div',{staticClass:"display_flex align-items_center",on:{"click":function($event){return _vm.checkUser(item.user_id)}}},[_c('div',{staticClass:"item-order"},[_vm._v(_vm._s(index + 4))]),_c('img',{staticClass:"item-img",attrs:{"src":item.head_portrait,"alt":""}}),_c('div',{staticClass:"display_flex align-items_center"},[[_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.nickname))])],_c('div',{staticClass:"item-level",style:({
                backgroundImage:
                  'url(' +
                  'img/level/level' +
                  _vm.rankCur +
                  '/' +
                  item.user_level +
                  '.png' +
                  ')',
              })})],2)]),_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"display_flex align-items_center"},[(_vm.rankCur == 0)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("财富值")]):_vm._e(),(_vm.rankCur == 1)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("魅力值")]):_vm._e(),(_vm.rankCur == 2)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("惊喜值")]):_vm._e(),(_vm.rankCur == 3)?_c('span',{staticStyle:{"color":"#ffffff","opacity":"0.7"}},[_vm._v("幸运值")]):_vm._e()]),[_c('p',{staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(_vm.is_admin ? item.total_charm_value : "****")+" ")])]],2)])}):_vm._e(),_c('van-overlay',{attrs:{"show":_vm.loading}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%","height":"100%"}},[_c('van-loading'),_c('div',{staticStyle:{"color":"#ffffff"}},[_vm._v("数据加载中...")])],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }